import userAPI from '../features/userAPI'
import {getAccessToken} from '../common/jwt'

export async function signIn(email, password) {

    try {
        // send request
        const {user, access, refresh} = await userAPI.login({email, password})
        return {
            isOk: true,
            access: access,
            refresh: refresh,
            data: user
        };
    } catch (error) {
        if (error.response && error.response.status === 401) {
            return {
                isOk: false,
                message: 'Email hoặc mật khẩu chưa đúng'
            };
        }
        return {
            isOk: false,
        };
    }
}

export async function getUser() {
    const accessToken = getAccessToken()

    if (accessToken == null) {
        return {
            isOk: false
        };
    }
    try {
        // send request
        const user = await userAPI.get_user()
        return {
            isOk: true,
            data: user
        };
    } catch {
        return {
            isOk: false
        };
    }
}

export async function createAccount(email, password) {
    try {
        const {user, access, refresh} = await userAPI.register({email, password})
        return {
            isOk: true,
        };
    } catch (error) {
        if (error.response && error.response.status === 400) {
            return {
                isOk: false,
                message: 'Email hoặc mật khẩu chưa đúng'
            };
        }
        return {
            isOk: false,
        };
    }
}

export async function changePassword(password, token) {
    try {
        // Send request
        await userAPI.change_password({password, token})
        return {
            isOk: true
        };
    } catch (error) {
        if (error.response) {
            return {
                isOk: false,
                message: "Link đổi mật khẩu hết hạn"
            }
        }
        return {
            isOk: false,
            message: "Link đổi mật khẩu hết hạn"
        }
    }
}

export async function resetPassword(email) {
    try {
        // Send request
        const response = await userAPI.reset_password({email})
        return {
            isOk: true
        };
    } catch (error) {
        if (error.response) {
            return {
                isOk: false,
                message: "Có lỗi xảy ra"
            }
        }
        return {
            isOk: false,
        };
    }
}