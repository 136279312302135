export const ACCESS_TOKEN_NAME = 'accessToken'
export const REFRESH_TOKEN_NAME = 'refreshToken'

export function setJWT(accessToken, refreshToken) {
    localStorage.setItem(ACCESS_TOKEN_NAME, accessToken)
    localStorage.setItem(REFRESH_TOKEN_NAME, refreshToken)
}

export function setAccessToken(accessToken) {
    localStorage.setItem(ACCESS_TOKEN_NAME, accessToken)
}


export function removeJWT() {
    localStorage.removeItem(ACCESS_TOKEN_NAME)
    localStorage.removeItem(REFRESH_TOKEN_NAME)
}

export function getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN_NAME)
}

export function getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_NAME)
}