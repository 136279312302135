import React, {useCallback, useRef, useState} from 'react';
import './profile.scss';
import Form, {ButtonItem, ButtonOptions, CustomRule, GroupItem, Item, Label, RequiredRule} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator'
import userAPI from '../../features/userAPI'
import {AsyncRule} from 'devextreme-react/validator'
import {useAuth} from '../../contexts/auth'
import {showNotification} from '../../common/notification'
import {removeJWT} from '../../common/jwt'
import {useNavigate} from 'react-router-dom'


const currentPasswordEditorOptions = {stylingMode: 'filled', placeholder: 'Nhập mật khẩu hiện tại', mode: 'password'};
const newPasswordEditorOptions = {stylingMode: 'filled', placeholder: 'Nhập mật khẩu mới', mode: 'password'};
const confirmedNewPasswordEditorOptions = {
    stylingMode: 'filled',
    placeholder: 'Nhập lại mật khẩu mới',
    mode: 'password'
};

export default function ProfileChangePasswordForm() {
    const {user, setUser} = useAuth()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const formData = useRef({'newPassword': ''});
    const onSubmit = useCallback(async (e) => {
        e.preventDefault();
        const {newPassword} = formData.current;
        setLoading(true)
        try {
            const resp = await userAPI.update_info({password: newPassword})
            setLoading(false)
            removeJWT()
            setUser(null)
            navigate('/login')
            showNotification('Thay đổi mật khẩu thành công. Xin vui lòng đăng nhập lại.', 'success')
        } catch (e) {
            console.log(e)
        }

    }, []);

    const confirmPassword = useCallback(
        ({value}) => value === formData.current.newPassword,
        []
    );

    const asyncPasswordValidation = useCallback(
        async (params) => {
            try {
                await userAPI.login({email: user.email, password: params.value})
                return true
            } catch (e) {
                if (e.response.status === 401) {
                    throw Error('Mật khẩu chưa đúng')
                }
            }
        }, [])

    return (
        <form onSubmit={onSubmit} style={{'padding': '20px'}}>
            <Form formData={formData.current} disabled={loading}>
                <GroupItem cssClass="form-group" colCount={2}>
                    <GroupItem cssClass="form-group" colSpan={1}>
                        <Item
                            dataField={'currentPassword'}
                            editorType={'dxTextBox'}
                            editorOptions={currentPasswordEditorOptions}
                        >
                            <RequiredRule message="Mật khẩu không được bỏ trống"/>
                            <AsyncRule
                                message="Mật khẩu hiện tại chưa khớp"
                                validationCallback={asyncPasswordValidation}
                            />
                            <Label text={"Mật khẩu hiện tại"}/>
                        </Item>
                        <Item
                            dataField={'newPassword'}
                            editorType={'dxTextBox'}
                            editorOptions={newPasswordEditorOptions}
                        >
                            <RequiredRule message="Mật khẩu không được bỏ trống"/>
                            <Label text={"Mật khẩu mới"}/>
                        </Item>
                        <Item
                            dataField={'confirmedNewPassword'}
                            editorType={'dxTextBox'}
                            editorOptions={confirmedNewPasswordEditorOptions}
                        >
                            <RequiredRule message="Mật khẩu không được bỏ trống"/>
                            <CustomRule
                                message={'Mật khẩu không khớp'}
                                validationCallback={confirmPassword}
                            />
                            <Label text={'Nhập lại mật khẩu mới'}/>
                        </Item>
                        <Item>
                            * Thay đổi mật khẩu sẽ cần đăng nhập lại tài khoản
                        </Item>
                        <ButtonItem>
                            <ButtonOptions
                                width={'120px'}
                                type={'default'}
                                useSubmitBehavior={true}
                            >
                                        <span className="dx-button-text">
                                          {
                                              loading
                                                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true}/>
                                                  : 'Cập nhật'
                                          }
                                        </span>
                            </ButtonOptions>
                        </ButtonItem>
                    </GroupItem>
                </GroupItem>
            </Form>
        </form>
    );
}
