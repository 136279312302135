import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import './dx-styles.scss';
import LoadPanel from 'devextreme-react/load-panel';
import {NavigationProvider, useNavigation} from './contexts/navigation';
import {AuthProvider, useAuth} from './contexts/auth';
import {useScreenSizeClass} from './utils/media-query';
import Content from './Content';
import UnauthenticatedContent from './UnauthenticatedContent';
import {browserHistory} from './common/history';
import ActivationContent from './ActivationContent'
import ErrorBoundary from './components/ErrorBoundary'

export let signOutGlobal = null;

function App() {
    const {user, loading, signOut} = useAuth();
    const {navigationData} = useNavigation()
    signOutGlobal = signOut
    console.log('user: ', user)
    console.log('navigation', navigationData)
    if (loading) {
        return <LoadPanel visible={true}/>;
    }
    console.log('rending after loading user')


    if (user) {
        if ([1, 3, 4].includes(user.status)) {
            return <ActivationContent user={user}/>
        }
        return <Content/>
    }

    return <UnauthenticatedContent/>

}

export default function Root() {
    const screenSizeClass = useScreenSizeClass();

    return (
        <ErrorBoundary>
            <Router history={browserHistory}>
                <AuthProvider>
                    <NavigationProvider>
                        <div className={`app ${screenSizeClass}`}>
                            <App/>
                        </div>
                    </NavigationProvider>
                </AuthProvider>
            </Router>
        </ErrorBoundary>
    );
}
