const isDevelopment = process.env.REACT_APP_ENV === 'development';

export const log = (...messages) => {
  if (isDevelopment) {
    console.log(...messages);
  }
};

export const logWarn = (...messages) => {
  if (isDevelopment) {
    console.warn(...messages);
  }
};

export const logError = (...messages) => {
  if (isDevelopment) {
    console.error(...messages);
  }
};