import React from 'react';
import {log, logError} from '../common/logger'
import {RefreshTokenError} from '../common/error'

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors and update state
        this.setState({
            hasError: true,
            error: error,
            errorInfo: errorInfo
        });
        logError("ErrorBoundary - An error occurred:", error, errorInfo);
        // You can also log the error to a logging service
        // logErrorToMyService(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            if (this.state.error instanceof RefreshTokenError) {
                log('Bắt RefreshTokenError trong ErrorBoundary');
                return;
                // Handle RefreshTokenError here
            }
            return <h1>Something went wrong.</h1> // Render your fallback UI here
        }

        return this.props.children;
    }
}