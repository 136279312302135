import React from 'react';
import './profile.scss';
import {Item} from 'devextreme-react/form';
import {TabPanel} from 'devextreme-react'
import ProfileForm from './ProfileForm'
import ProfileChangePasswordForm from './ChangePasswordForm'


export default function Profile() {
    return (
        <div className="content-block dx-card responsive-paddings">
            <TabPanel id="tabPanel">
                <Item title="Thông tin tài khoản" icon="accountbox">
                    <ProfileForm/>
                </Item>
                <Item title="Đổi mật khẩu" icon="login">
                    <ProfileChangePasswordForm/>
                </Item>
            </TabPanel>
        </div>
    );
}
