import './shopee-auth.scss';
import {useSearchParams} from 'react-router-dom';

export default () => {

    let [searchParams] = useSearchParams()
    let authCode = searchParams.get('code');
    let shopID = searchParams.get('shop_id');

    return (
        <>
            <h2 className={'content-block'}>Tiktokshop Auth</h2>
            <div className={'content-block'}>
                <div className={'dx-card responsive-paddings'}>
                    <p> Authorization code: {authCode} </p>
                    <p> Shop ID: {shopID} </p>

                </div>
            </div>
        </>
    )
}

