import axios from 'axios'
import queryString from "query-string";
import {log, logWarn} from './logger'
import {showNotification} from './notification'
import {navigateTo} from './navigation'
import {getAccessToken, getRefreshToken, setAccessToken} from './jwt'
import userAPI from '../features/userAPI'
import {signOutGlobal} from '../App'

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL
const REFRESH_TOKEN_PATH = '/user/refresh-token/'
const LOGIN_PATH = '/user/login/'
const ACTIVIATION_PATH = '/user/activate/'

export const apiClientNoAuth = axios.create({
    baseURL: API_BASE_URL,
    responseType: 'json',
    headers: {
        'Content-Type': 'application/json',
    },
})

export const apiClient = axios.create({
    baseURL: API_BASE_URL,
    responseType: 'json',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`,
    },
})

async function handleRefreshTokenInvalid(error) {
    log('RefreshToken is invalid or expired, logging out user.', error);
    signOutGlobal()
    navigateTo('/login')
    log('đã chuyển trang sang login')
    showNotification('Phiên đăng nhập đã hết hạn. Xin vui lòng đăng nhập lại.')
    throw error
}

async function regenerateAccessToken(error) {
    logWarn("Server responded with 401 Unauthorized: ", error);
    log('New request with refresh token');

    const refreshToken = getRefreshToken();
    const {access} = await userAPI.refresh_token({refreshToken});

    setAccessToken(access);
    updateAPIClientAuthorization(access);

    const config = {...error.config}
    config.headers['Authorization'] = `Bearer ${access}`

    return await axios.request(config);
}

function updateAPIClientAuthorization(access) {
    apiClient.defaults.headers['Authorization'] = `Bearer ${access}`;
}

function handleInternalServerError(error) {
    logWarn("Server responded with 500 Internal Server Error: ", error);
    showNotification('Có lỗi server 500 xảy ra. Xin vui lòng thử lại sau!', 'error');
    throw error
}

function handleFallbackError(error) {
    if (error.code === "ERR_NETWORK") {
        showNotification("Có lỗi kết nối mạng xảy ra.", 'error')
    }
    const errorMessage = error.request
        ? "No response was received for the request: "
        : "Error setting up the request: ";
    logWarn('handleFallbackError: ', errorMessage, error);
    throw error
}


apiClient.interceptors.request.use(
    (config) => {
        config.paramsSerializer = (params) => {
            return queryString.stringify(params, {arrayFormat: 'repeat'})
        }
        config.url = config.url.endsWith('/') ? config.url : config.url + '/';
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

apiClientNoAuth.interceptors.request.use(
    (config) => {
        config.paramsSerializer = (params) => {
            return queryString.stringify(params, {arrayFormat: 'repeat'})
        }
        config.url = config.url.endsWith('/') ? config.url : config.url + '/';
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

async function handleError(error) {
    if (error.response) {
        const {status: statusCode, config: {url: errorUrl}} = error.response;
        if (statusCode === 401 && errorUrl !== LOGIN_PATH && errorUrl !== REFRESH_TOKEN_PATH && errorUrl !== ACTIVIATION_PATH)
            return await regenerateAccessToken(error);
        else if (errorUrl === REFRESH_TOKEN_PATH)
            return handleRefreshTokenInvalid(error);
        else if (statusCode === 500)
            return handleInternalServerError(error);
        else {
            // re-throw error for calling code to catch this error
            logWarn('re-throw error for calling code to catch this error: ', error)
            throw error
        }
    }
    // no error.response
    return handleFallbackError(error);
}


function handleSuccess(response) {
    return response
}

apiClient.interceptors.response.use(handleSuccess, (error) => handleError(error))
apiClientNoAuth.interceptors.response.use(handleSuccess, (error) => handleError(error))
