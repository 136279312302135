import {Navigate, Route, Routes} from 'react-router-dom';
import {SingleCard} from './layouts';
import {ChangePasswordForm, CreateAccountForm, LoginForm, ResetPasswordForm} from './components';
import {ActivationPage} from './ActivationContent'

export default function UnauthenticatedContent() {
    const REACT_APP_BUILD_TIME = process.env.REACT_APP_BUILD_TIME
    const REACT_APP_GIT_VERSION = process.env.REACT_APP_GIT_VERSION
    const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL
    return (
        <Routes>
            <Route
                path="/user/activate/"
                element={<ActivationPage/>}
            />
            <Route
                path='/login'
                element={
                    <SingleCard title="Sign In">
                        <LoginForm/>
                    </SingleCard>
                }
            />
            <Route
                path='/create-account'
                element={
                    <SingleCard title="Sign Up">
                        <CreateAccountForm/>
                    </SingleCard>
                }
            />
            <Route
                path='/reset-password'
                element={
                    <SingleCard
                        title="Reset Password"
                        description="Please enter the email address that you used to register, and we will send you a link to reset your password via Email."
                    >
                        <ResetPasswordForm/>
                    </SingleCard>
                }
            />
            <Route
                path='/user/forgot-password/'
                element={
                    <SingleCard title="Change Password">
                        <ChangePasswordForm/>
                    </SingleCard>
                }
            />
            <Route
                path='/account-created/'
                element={
                    <SingleCard title="Đăng ký tài khoản thành công">
                        Chúng tôi đã gửi email đính kèm link kích hoạt tài khoản. Xin vui lòng kiểm tra email.
                    </SingleCard>
                }
            />
            <Route
                path='/email-reset-password/'
                element={
                    <SingleCard title="Đã gửi email thay đổi mật khẩu">
                        Chúng tôi đã gửi email đính kèm link thay đổi mật khẩu. Xin vui lòng kiểm tra email.
                    </SingleCard>
                }
            />
            <Route
                path='/status'
                element={
                    <SingleCard title="System Status">
                        <div> Base API URL: {REACT_APP_BASE_API_URL}</div>
                        <div> Build time: {REACT_APP_BUILD_TIME} </div>
                        <div> Commit hash: {REACT_APP_GIT_VERSION} </div>
                    </SingleCard>
                }
            />
            <Route path='*' element={<Navigate to={'/login'}/>}></Route>
        </Routes>
    );
}
