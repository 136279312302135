import {
    GetUserPage,
    HomePage,
    LazadaAuthPage,
    ProfilePage,
    SapoAuthPage,
    ShopeeAuthPage,
    TasksPage,
    TestPage,
    TiktokAccountAuthPage,
    TiktokadsAuthPage,
    TiktokshopAuthPage
} from './pages';
import {withNavigationWatcher} from './contexts/navigation';

const routes = [
    {
        path: '/tasks',
        element: TasksPage
    },
    {
        path: '/profile',
        element: ProfilePage
    },
    {
        path: '/home',
        element: HomePage
    },
    {
        path: '/tiktokshop-auth',
        element: TiktokshopAuthPage
    },
    {
        path: '/shopee-auth',
        element: ShopeeAuthPage
    },
    {
        path: '/shopee-auth',
        element: ShopeeAuthPage
    },
    {
        path: '/test',
        element: TestPage
    },
    {
        path: '/get-user',
        element: GetUserPage
    },
    {
        path: '/tiktok-ads-auth',
        element: TiktokadsAuthPage
    },
    {
        path: '/lazada-auth',
        element: LazadaAuthPage
    },
    {
        path: '/sapo-auth',
        element: SapoAuthPage
    },
    {
        path: '/tiktok-account-auth',
        element: TiktokAccountAuthPage
    }
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
