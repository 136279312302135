export const navigation = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Examples',
        icon: 'folder',
        items: [
            {
                text: 'Profile',
                path: '/profile'
            },
            {
                text: 'Tasks',
                path: '/tasks'
            }
        ]
    },
    {
        text: 'Tiktokshop Auth',
        path: '/tiktokshop-auth',
        icon: 'folder'
    },
    {
        text: 'Shopee Auth',
        path: '/shopee-auth',
        icon: 'folder'
    },
    {
        text: 'Test page',
        path: '/test',
        icon: 'folder'
    },
    {
        text: 'Get User',
        path: '/get-user',
        icon: 'folder'
    },
    {
        text: 'Tiktok Ads Auth',
        path: '/tiktok-ads-auth',
        icon: 'folder'
    },
    {
        text: 'Lazada Auth',
        path: '/lazada-auth',
        icon: 'folder'
    },
    {
        text: 'Sapo Auth',
        path: '/sapo-auth',
        icon: 'folder'
    },
    {
        text: 'Tiktok Account Auth',
        path: '/tiktok-account-auth',
        icon: 'folder'
    }
]