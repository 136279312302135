import notify from 'devextreme/ui/notify'


export const MessageType = {
    ERROR: 'error',
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning'
}

export const showNotification = (message, type = MessageType.INFO, displayTime=5000) => {
    notify(
        {
            message: message,
            width: 400,
            type: type,
            displayTime: displayTime,
        },
        {
            position: 'bottom center',
        },
    );
}
