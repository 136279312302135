import React, {useCallback, useRef, useState} from 'react';
import './profile.scss';
import Form, {ButtonItem, ButtonOptions, CustomRule, GroupItem, Item, Label, RequiredRule} from 'devextreme-react/form';
import {useAuth} from '../../contexts/auth'
import LoadIndicator from 'devextreme-react/load-indicator'
import {AsyncRule} from 'devextreme-react/validator'
import userAPI from '../../features/userAPI'
import {showNotification} from '../../common/notification'


const emailEditorOptions = {stylingMode: 'filled', placeholder: 'Email', mode: 'email'};
const fullNameEditorOptions = {stylingMode: 'filled', placeholder: 'Họ tên', mode: 'text'};
const phoneEditorOptions = {stylingMode: 'filled', placeholder: 'Số điện thoại', mode: 'text'};
const passwordEditorOptions = {stylingMode: 'filled', placeholder: 'Nhập mật khẩu hiện tại', mode: 'password'};


export default function ProfileForm() {
    const {user, setUser} = useAuth()
    const [loading, setLoading] = useState(false);
    const formData = useRef(user);
    const onSubmit = useCallback(async (e) => {
        e.preventDefault();
        const {full_name, phone} = formData.current;
        setLoading(true);
        const {user} = await userAPI.update_info({full_name, phone})
        setUser(user)
        setLoading(false);
        showNotification('Thay đổi thông tin thành công', 'success')
    }, []);

    const phoneValidation = useCallback(
        ({value}) => {
            const regex = /^\d{9,11}$/;
            return regex.test(value);
        },
        []
    );

    const asyncPasswordValidation = useCallback(
        async (params) => {
            try {
                await userAPI.login({email: user.email, password: params.value})
                return true
            } catch (e) {
                if (e.response.status === 401) {
                    throw Error('Mật khẩu chưa đúng')
                }
            }
        }, [])


    return (
        <form onSubmit={onSubmit} style={{'padding': '20px'}}>
            <Form formData={formData.current} disabled={loading}>
                <GroupItem cssClass="form-group" colCount={2}>
                    <GroupItem cssClass="form-group" colSpan={1}>
                        <Item
                            dataField={'email'}
                            editorType={'dxTextBox'}
                            disabled={true}
                            editorOptions={emailEditorOptions}
                        >
                            <Label text={"Email"}/>
                        </Item>
                        <Item
                            dataField={'full_name'}
                            editorType={'dxTextBox'}
                            editorOptions={fullNameEditorOptions}
                        >
                            <RequiredRule message="Họ tên không được bỏ trống"/>
                            <Label text={"Tên hiển thị"}/>
                        </Item>
                        <Item
                            dataField={'phone'}
                            editorType={'dxTextBox'}
                            editorOptions={phoneEditorOptions}
                        >
                            <RequiredRule message="Mật khẩu không được bỏ trống"/>
                            <CustomRule
                                message={'Số điện thoại không đúng định dạng'}
                                validationCallback={phoneValidation}
                            />
                            <Label text={"Số điện thoại"}/>
                        </Item>
                        <Item
                            dataField={'password'}
                            editorType={'dxTextBox'}
                            editorOptions={passwordEditorOptions}
                        >
                            <RequiredRule message="Mật khẩu không được bỏ trống"/>
                            <AsyncRule
                                message="Mật khẩu hiện tại chưa khớp"
                                validationCallback={asyncPasswordValidation}
                            />
                            <Label text={"Mật khẩu hiện tại"}/>
                        </Item>
                        <Item>
                            * Xin hãy cập nhập đúng thông tin cá nhân để chúng tôi có thể hỗ trợ bạn khi gặp sự cố
                            hoặc quên mật khẩu.
                        </Item>
                        <ButtonItem>
                            <ButtonOptions
                                width={'120px'}
                                type={'default'}
                                useSubmitBehavior={true}
                            >
                                <span className="dx-button-text">
                                  {
                                      loading
                                          ? <LoadIndicator width={'24px'} height={'24px'} visible={true}/>
                                          : 'Cập nhật'
                                  }
                                </span>
                            </ButtonOptions>
                        </ButtonItem>
                    </GroupItem>
                </GroupItem>
            </Form>
        </form>
    );
}
