import React, {useCallback, useRef, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Form, {ButtonItem, ButtonOptions, CustomRule, EmailRule, Item, Label, RequiredRule} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import LoadIndicator from 'devextreme-react/load-indicator';
import {createAccount} from '../../api/auth';

import './CreateAccountForm.scss';

export default function CreateAccountForm() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const formData = useRef({email: '', password: ''});
    const [createdSuccess, setCreatedSuccess] = useState(true);

    const onSubmit = useCallback(async (e) => {
        e.preventDefault();
        const {email, password} = formData.current;
        setLoading(true);

        const result = await createAccount(email, password);
        setLoading(false);

        if (result.isOk) {
            setCreatedSuccess(true)
            navigate('/account-created');
        } else {
            setLoading(false);
            if (!!result.message) {
                notify(result.message, 'error', 3000);
            }
        }
    }, [navigate]);

    const confirmPassword = useCallback(
        ({value}) => value === formData.current.password,
        []
    );


    return (
        <form className={'create-account-form'} onSubmit={onSubmit}>
            <Form formData={formData.current} disabled={loading}>
                <Item
                    dataField={'email'}
                    editorType={'dxTextBox'}
                    editorOptions={emailEditorOptions}
                >
                    <RequiredRule message="Email không được bỏ trống."/>
                    <EmailRule message="Email chưa đúng định dạng."/>
                    <Label visible={false}/>
                </Item>
                <Item
                    dataField={'password'}
                    editorType={'dxTextBox'}
                    editorOptions={passwordEditorOptions}
                >
                    <RequiredRule message="Mật khẩu không được bỏ trống"/>
                    <Label visible={false}/>
                </Item>
                <Item
                    dataField={'confirmedPassword'}
                    editorType={'dxTextBox'}
                    editorOptions={confirmedPasswordEditorOptions}
                >
                    <RequiredRule message="Mật khẩu không được bỏ trống"/>
                    <CustomRule
                        message={'Mật khẩu không khớp'}
                        validationCallback={confirmPassword}
                    />
                    <Label visible={false}/>
                </Item>
                <Item>
                    <div className='policy-info'>
                        By creating an account, you agree to the <Link to="#">Terms of Service</Link> and <Link to="#">Privacy
                        Policy</Link>
                    </div>
                </Item>
                <ButtonItem>
                    <ButtonOptions
                        width={'100%'}
                        type={'default'}
                        useSubmitBehavior={true}
                    >
            <span className="dx-button-text">
              {
                  loading
                      ? <LoadIndicator width={'24px'} height={'24px'} visible={true}/>
                      : 'Create a new account'
              }
            </span>
                    </ButtonOptions>
                </ButtonItem>
                <Item>
                    <div className={'login-link'}>
                        Have an account? <Link to={'/login'}>Sign In</Link>
                    </div>
                </Item>
            </Form>
        </form>
    );
}

const emailEditorOptions = {stylingMode: 'filled', placeholder: 'Email', mode: 'email'};
const passwordEditorOptions = {stylingMode: 'filled', placeholder: 'Password', mode: 'password'};
const confirmedPasswordEditorOptions = {stylingMode: 'filled', placeholder: 'Confirm Password', mode: 'password'};
