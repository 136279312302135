import React, {useEffect, useState} from 'react';
import './get-user.scss';
import userAPI from '../../features/userAPI'

export default () => {
    const [pending, setPending] = useState()
    const [user, setUser] = useState()
    useEffect(() => {
        async function getUser() {
            try {
                const user = await userAPI.get_user()
                setUser(user)
            } catch (e) {

            }
        }

        getUser()
    }, [])

    return (
        <React.Fragment>
            <h2 className={'content-block'}>Get User</h2>
            <div className={'content-block'}>
                <div className={'dx-card responsive-paddings'}>
                    {JSON.stringify(user)}
                </div>
            </div>
        </React.Fragment>
    )
}


