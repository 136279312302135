import {Route, Routes, useNavigate, useSearchParams} from 'react-router-dom';
import {SingleCard} from './layouts';
import {useEffect, useState} from 'react'
import userAPI from './features/userAPI'
import {showNotification} from './common/notification'
import {useAuth} from './contexts/auth'

export function AlertStatusPage({user}) {

    switch (user.status) {
        case 1:
            return (
                <SingleCard title="Tài khoản chờ kích hoạt qua email">
                    <p> Xin vui lòng kiểm tra email <b>{user.email} </b> để kích hoạt tài khoản.</p>
                </SingleCard>
            )
        case 3:
            return (
                <SingleCard title="Tài khoản bị khoá">
                    <p>Người dùng <b>{user.email} </b> bị xoá bởi chủ tài khoản. Xin vui lòng liên hệ chủ tài khoản
                        hoặc hotline.</p>;
                </SingleCard>
            )
        case 4:
            return (
                <SingleCard title="Tài khoản bị khoá">
                    <p>Tài khoản <b>{user.email} </b> bị khoá bởi hệ thống. Xin vui lòng liên hệ hotline để được hỗ trợ.
                    </p>;
                </SingleCard>
            )
    }
}

const notificationText = 'Kích hoạt tài khoản thành công'

export function ActivationPage() {
    let [searchParams] = useSearchParams()
    const {setUser} = useAuth();
    let token = searchParams.get('token');
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null)
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const {user} = await userAPI.activate_account({token})
                setUser(user)
                navigate('/home')
                showNotification(notificationText, 'success', 3000)
            } catch (e) {
                if (e.response.status === 404) {
                    setError('token hết hạn hoặc không đúng')
                }
            } finally {
                setIsPending(false)
            }
        }
        fetchData()
    }, [])

    if (isPending) {
        return 'Loading...';
    } else if (error) {
        return (
            <SingleCard title="Có lỗi xảy ra">
                <div>Thời gian kích hoạt tài khoản hết hạn hoặc có lỗi xảy ra.
                    Bấm quên mật khẩu để lấy lại mật khẩu mới.
                </div>
            </SingleCard>
        )
    }

}
export default function ActivationContent({user}) {
    return (
        <Routes>
            <Route
                path='/user/activate/'
                element={<ActivationPage/>}
            />
            <Route
                path='*'
                element={<AlertStatusPage user={user}/>}
            />
        </Routes>
    );
}
