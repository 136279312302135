import {apiClient, apiClientNoAuth} from '../common/api-call'

const userAPI = {
    register: async ({email, password}) => {
        const response = await apiClientNoAuth.post('/user/register/', {email, password})
        const {user, access, refresh} = response.data
        return {user, access, refresh}
    },
    login: async ({email, password}) => {
        const response = await apiClientNoAuth.post('/user/login/', {email, password})
        const {user, access, refresh} = response.data
        return {user, access, refresh}
    },
    reset_password: async ({email}) => {
        const response = await apiClientNoAuth.post(`/user/reset-password/`, {email})
        const {user, access, refresh} = response.data
        return {user, access, refresh}
    },
    change_password: async ({password, token}) => {
        const response = await apiClientNoAuth.post(`/user/change-password/`, {password, token})
        const {user, access, refresh} = response.data
        return {user, access, refresh}
    },
    activate_account: async ({token}) => {
        const response = await apiClientNoAuth.post(`/user/activate/`, {token})
        const {user, access, refresh} = response.data
        return {user, access, refresh}
    },
    get_user: async () => {
        const response = await apiClient.get('/user/')
        const user = response.data
        return user
    },
    refresh_token: async ({refreshToken}) => {
        const response = await apiClient.post('/user/refresh-token/', {refresh: refreshToken})
        const {user, access} = response.data
        return {user, access}
    },
    update_info: async ({password, full_name, phone}) => {
        const response = await apiClient.post(`/user/update/`, {password, full_name, phone})
        const {user, access, refresh} = response.data
        return {user, access, refresh}
    }
}

export default userAPI