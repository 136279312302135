import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {getUser, signIn as sendSignInRequest} from '../api/auth';
import {removeJWT, setJWT} from '../common/jwt'
import {apiClient} from '../common/api-call'
import {log} from '../common/logger'


function AuthProvider(props) {
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        async function getUser_() {
            const result = await getUser();
            if (result.isOk) {
                setUser(result.data);
            }
            setLoading(false);
        }
        getUser_()
    }, []);

    const signIn = useCallback(async (email, password) => {
        const result = await sendSignInRequest(email, password);
        if (result.isOk) {
            setUser(result.data);
            setJWT(result.access, result.refresh)
            apiClient.defaults.headers['Authorization'] = `Bearer ${result.access}`;
        }
        return result;
    }, []);

    const signOut = useCallback(() => {
        setUser(undefined);
        log('đã xoá user')
        removeJWT()
        log('đã xoá token')
    }, []);


    return (
        <AuthContext.Provider value={{user, setUser, signIn, signOut, loading}} {...props} />
    );
}

const AuthContext = createContext({loading: false});
const useAuth = () => useContext(AuthContext);

export {AuthProvider, useAuth}

