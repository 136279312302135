import React from 'react';
import {useSearchParams} from 'react-router-dom';

export default () => {

    let [searchParams] = useSearchParams()
    let authCode = searchParams.get('code');

    return (
        <>
            <h2 className={'content-block'}>Lazada Auth</h2>
            <div className={'content-block'}>
                <div className={'dx-card responsive-paddings'}>
                    Authorization code: {authCode}
                </div>
            </div>
        </>
    )
}
